.wrapper_404 {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 75px;
	& > * {
		margin: 0 30px;
	}
	img {
		width: 380px;
		height: auto;
	}
	.right {
		width: 450px;
		h1 {
			font-weight: 600;
			font-size: 48px;
			line-height: 54px;
			margin: 30px 0;
			text-transform: uppercase;
		}
		p {
			font-weight: 600;
			font-size: 18px;
			line-height: 35px;
		}
		button {
			width: 219px;
			height: 56px;
			background: #4bafc1;
			border-radius: 5px;
			font-weight: 600;
			font-size: 18px;
			line-height: 27px;
			color: #ffffff;
			margin: 30px 0;
		}
	}
}

@media (max-width: 768px) {
	.wrapper_404 {
		flex-direction: column;
		width: 100%;
		height: auto;
		position: static;
		transform: translate(0);
		.right {
			margin-top: 70px;
			text-align: center;
		}
	}
}

@media (max-width: 450px) {
	.wrapper_404 {
		padding: 80px 25px;
		& > * {
			margin: 0px;
		}
		img {
			margin: 0;
			width: 100%;
		}
		.right {
			width: 100%;
			h1 {
				font-size: 24px;
				line-height: 54px;
			}
			p {
				font-weight: 500;
				font-size: 14px;
				line-height: 35px;
			}
			button {
				width: 158px;
				height: 40px;
				font-size: 13px;
				line-height: 19px;
			}
		}
	}
}
