@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
	body {
		margin: 0;
		font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
			'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
			'Helvetica Neue', sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	code {
		font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
			monospace;
	}

	html,
	body,
	#root {
		@apply min-h-screen h-full w-full;
	}

	* {
		@apply box-border font-fredoka;
	}

	a {
		@apply text-blue-500;
	}

	button {
		&:disabled {
			@apply opacity-80 cursor-not-allowed;
		}
	}

	.country-select {
		&::-webkit-scrollbar {
			width: 0px;
		}
	}

	.snap-scroll-container {
		-webkit-overflow-scrolling: touch;
		-ms-overflow-style: none;
		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
		}
	}

	.hide-scrollbar {
		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
		}
	}

	.grabbing {
		cursor: grabbing !important;
	}

	.burger-icon {
		transition: all 0.2s ease-in-out;
		@apply relative h-[3px] w-[25px] rotate-180 rounded-sm;

		&::before,
		&::after {
			content: '';
			transition: all 0.2s ease-in-out;
			@apply absolute h-[3px] w-[25px] bg-inherit -mt-2 rounded-sm;
		}

		&::before {
			@apply mt-2;
		}

		&.open {
			@apply h-0;

			&::before {
				@apply -rotate-45 inset-0 m-0;
			}

			&::after {
				@apply rotate-45 inset-0 m-0;
			}
		}
	}

	.bullet {
		&::before {
			content: '●';
			@apply text-primary-25 mr-3;
		}
	}

	.transition-500 {
		transition: all 0.5s ease-out;
	}

	.tooltip-arrow {
		@apply flex items-center justify-center;

		&::before {
			content: '';
			@apply absolute -bottom-1 left-1/2 -translate-x-1/2 border-l-8 border-r-8 border-t-8 border-solid border-transparent border-t-black;
		}
	}

	.lds-wrapper {
		@apply inline-block relative w-20 h-20;

		div {
			animation: lds-default 1.2s linear infinite;
			@apply absolute w-2 h-2 rounded-[50%] bg-white;

			&:nth-child(1) {
				animation-delay: 0s;
				top: 37px;
				left: 66px;
			}

			&:nth-child(2) {
				animation-delay: -0.1s;
				top: 22px;
				left: 62px;
			}

			&:nth-child(3) {
				animation-delay: -0.2s;
				top: 11px;
				left: 52px;
			}

			&:nth-child(4) {
				animation-delay: -0.3s;
				top: 7px;
				left: 37px;
			}

			&:nth-child(5) {
				animation-delay: -0.4s;
				top: 11px;
				left: 22px;
			}

			&:nth-child(6) {
				animation-delay: -0.5s;
				top: 22px;
				left: 11px;
			}

			&:nth-child(7) {
				animation-delay: -0.6s;
				top: 37px;
				left: 7px;
			}

			&:nth-child(8) {
				animation-delay: -0.7s;
				top: 52px;
				left: 11px;
			}

			&:nth-child(9) {
				animation-delay: -0.8s;
				top: 62px;
				left: 22px;
			}

			&:nth-child(10) {
				animation-delay: -0.9s;
				top: 66px;
				left: 37px;
			}

			&:nth-child(11) {
				animation-delay: -1s;
				top: 62px;
				left: 52px;
			}

			&:nth-child(12) {
				animation-delay: -1.1s;
				top: 52px;
				left: 62px;
			}
		}

		@keyframes lds-default {
			0%,
			20%,
			80%,
			100% {
				transform: scale(1);
			}
			50% {
				transform: scale(1.5);
			}
		}
	}

	.flex-center {
		@apply flex items-center justify-center;
	}

	.position-center {
		@apply top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 absolute;
	}

	.theme-scroll {
		&::-webkit-scrollbar {
			@apply w-2 bg-primary-4;
			border-radius: 0 8px 8px 0;
		}

		&::-webkit-scrollbar-thumb {
			@apply bg-primary w-2;
			border-radius: 0 8px 8px 0;
		}
	}

	.close-icon {
		@apply absolute top-0 right-0 m-2 cursor-pointer w-7 h-7 bg-primary-10 rounded-full;

		&::after,
		&::before {
			content: '';
			@apply absolute top-3 left-1.5 w-4 h-[3px] bg-primary-1 rounded-sm;
		}

		&::after {
			@apply rotate-45;
		}

		&::before {
			@apply -rotate-45;
		}
	}
}
